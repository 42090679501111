<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
  >
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
    >
      <!-- <a-form-model-item
        label="代理商"
        prop="code"
      >
        <div class="slect_icon">
          <a-select
            placeholder="请选择"
            v-model="form.agentId"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            allow-clear
          >
            <a-select-option
              v-for="(d, index) in agentList"
              :key="index"
              :value="d.id"
            >{{ d.name }}</a-select-option>
          </a-select>
          <a-icon
            type="plus-circle"
            class="icon_cir"
            @click="handleJiaDai"
          />
        </div>
      </a-form-model-item> -->
      <a-form-model-item
        label="所属菜单"
        prop="type"
      >
        <a-select
          v-model="form.type"
          placeholder="请选择"
          style="width: 100%"
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          allow-clear
        >
          <a-select-option
            v-for="(item, key) in typeOptions"
            :key="key"
            :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="设备名称"
        prop="devName"
      >
        <a-input
          v-model="form.devName"
          placeholder="请输入"
          :maxLength="30"
        />
      </a-form-model-item>
      <a-form-model-item
        label="设备序列号"
        prop="devCode"
      >
        <a-input
          v-model="form.devCode"
          placeholder="请输入"
          :maxLength="30"
        />
      </a-form-model-item>
      <a-form-model-item
        label="设备图片"
        prop="devImgUrl"
      >
        <div
          class="ant-upload-preview"
          @click="$refs.upImg.edit(form.picPath)"
        >
          <div
            class="mask"
            :style="form.devImgUrl ? 'opacity: 0' : 'opacity: 1'"
          >
            <a-icon type="plus" />
          </div>
          <img
            :src="form.devImgUrl"
            v-if="form.devImgUrl"
            style="width: 100px;height: 100px"
          />
        </div>
      </a-form-model-item>
      <a-form-model-item
        label="	设备类型"
        prop="devTypeId"
      >
        <div class="slect_icon">
          <a-select
            v-model="form.devTypeId"
            placeholder="请选择"
            style="width: 100%"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            allow-clear
          >
            <a-select-option
              v-for="(item, key) in deviceTypeList"
              :key="key"
              :value="item.id"
            >{{ item.devName }}</a-select-option>
          </a-select>
          <a-icon
            type="plus-circle"
            class="icon_cir"
            @click="$refs.createType.handleAdd()"
          />
        </div>
      </a-form-model-item>
      <a-form-model-item
        label="设备模式"
        prop="patternType"
      >
        <a-radio-group
          v-model="form.patternType"
          button-style="solid"
        >
          <a-radio-button :value="0">自动</a-radio-button>
          <a-radio-button :value="1">手动</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        label="到期时间"
        prop="expirationTimes"
      >
        <a-date-picker
          @change="onChangeTime"
          v-model="form.expirationTimes"
        />
      </a-form-model-item>
      <a-form-model-item
        label="文件"
        prop="mp3AudioUrl"
      >
        <a-upload
          action="http://47.93.28.184:8071/admin-api/common/local/file/upload"
          :headers="header"
          class="upload-demo"
          ref="upload"
          @change="handleChange"
          accept=".mp3"
          :showUploadList="false"
        >
          <a-button :loading="uploadLoading"> <a-icon type="upload" /> 选取文件 </a-button>
          <span v-if="fileName">{{ fileName }}</span>
          <span v-show="false">{{ form.mp3AudioUrl }}</span>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item
        label="地址"
        prop="addressName"
      >
        <a-input
          v-model="form.addressName"
          placeholder="请选择地址"
          disabled
        />
      </a-form-model-item>
      <a-form-model-item
        label="选择地址"
        prop="serialNumber"
      >
        <addMap
          @getInfo="handleAddress"
          ref="map"
          v-if="open"
          @changeAddress="handleChangeAdd"
        ></addMap>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button
            type="primary"
            :loading="submitLoading"
            @click="submitForm"
          >
            保存
          </a-button>
          <a-button
            type="dashed"
            @click="cancel"
          >
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <up-img-modal
      ref="upImg"
      :width="165"
      :height="169"
      @ok="setImg"
    />
    <CreateType
      ref="createType"
      @ok="getType"
    ></CreateType>
  </a-drawer>
</template>

<script>
import { getDevice, addDevice, updateDevice } from '@/api/project/device'
import { listDeviceType } from '@/api/project/deviceType'
import addMap from '@/components/AMap/index.vue'
import upImgModal from '@/components/upImgModal'
import store from '@/store'
import CreateType from '../../deviceType/modules/CreateForm.vue'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    addMap,
    upImgModal,
    CreateType
  },
  data () {
    return {
      previewVisible: false,
      fileList: [
      ],
      agentList: [],
      submitLoading: false,
      header: { Authorization: 'Bearer ' + store.getters.access_token },
      formTitle: '',
      fileName: '',
      // 表单参数
      form: {
        type: undefined,
        id: 0,
        devName: '',
        devCode: '',
        devImgUrl: '',
        patternType: 0,
        devTypeId: undefined,
        addressName: '',
        log: '',
        lat: '',
        cityCode: '',
        provinceCode: '',
        areaCode: '',
        mp3AudioUrl: '',
        expirationTimes: ''
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      uploadLoading: false,
      rules: {
        type: [
          { required: true, message: '请选择设备类型', trigger: 'blur' }
        ],
        addressName: [
          { required: true, message: '请在地图上下选择地址', trigger: 'blur' }
        ],
        devCode: [
          { required: true, message: '请输入设备序列号', trigger: 'blur' }
        ],
        devName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        devImgUrl: [
          { required: true, message: '请上传设备图片', trigger: 'blur' }
        ],
        devTypeId: [
          { required: true, message: '请选择设备类型', trigger: 'blur' }
        ]
      },
      deviceTypeList: [],
      typeOptions: [
        { name: '设备管理', value: 0 },
        { name: '车辆管理', value: 1 }
      ]
    }
  },
  filters: {
  },
  created () {

  },
  computed: {
  },
  watch: {
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    setImg (url) {
      console.log(url, '大大大大大====')
      this.form.devImgUrl = url
    },
    onChangeTime (date, dateString) {
      // onChangeTime
      this.form.expirationTimes = dateString + ' 23:59:59'
      // console.log(date, dateString, '嘟嘟嘟嘟')
    },
    handleJiaDai () {
      console.log(this.$refs.createForm, 'this.$refs.createFormthis.$refs.createFormthis.$refs.createForm==')
      this.$nextTick(() => {
        this.$refs.createForm.handleAdd()
      })
    },
    handleChangeAdd (e) {
      this.form.lat = e.lat
      this.form.log = e.log
      this.form.addressName = e.address
      this.$forceUpdate()
    },
    // 上传图片
    uploadImages ({ fileList }) {
      console.log('fileList', fileList)
      this.fileList = fileList
    },
    // 上传成功
    handleChange (info) {
      console.log(info.file.status, info.file)
      if (info.file.status !== 'uploading') {
        this.uploadLoading = true
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        console.log(info.file.status, info.file.response.data.url, info.file)
        this.fileName = info.file.name
        console.log(info.file.response.data, ' info.file.response.data')
        this.form.mp3AudioUrl = info.file.response.data
        this.$message.success(`${info.file.name} 上传成功`)
        this.uploadLoading = false
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
        this.uploadLoading = false
      }
    },
    // 查询所有的设备类型
    getType () {
      listDeviceType({ status: 1 }).then(res => {
        this.deviceTypeList = res.data
      })
    },
    onChange (e, data) {
      console.log(e, data)
      this.form.produceDate = data
    },
    onClose () {
      this.open = false
    },
    // 点击选择地址
    handleAddress (e) {
      console.log(e, 'mmmm')
      this.form.addressName = e.detailedAddress
      this.showId = false
      this.form.log = e.lng
      this.form.lat = e.lat
      this.form.cityCode = e.info.city ? e.info.city : e.info.province
      this.form.provinceCode = e.info.province
      this.form.areaCode = e.info.district
      this.$refs['form'].clearValidate(['address'])
      this.$forceUpdate()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        type: undefined,
        id: 0,
        devName: '',
        devCode: '',
        devImgUrl: '',
        patternType: 0,
        devTypeId: undefined,
        addressName: '',
        log: '',
        lat: '',
        cityCode: '',
        provinceCode: '',
        areaCode: '',
        mp3AudioUrl: '',
        expirationTimes: ''
      }
      this.fileName = ''
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.getType()
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDevice({ 'id': id }).then(response => {
        this.form = response.data
        if (this.form.agentId === '-1') {
          this.form.agentId = undefined
        }
        this.$nextTick(() => {
          this.$refs.map.init(this.form.log, this.form.lat, true)
        })
        this.open = true
        this.formTitle = '修改'
      })
      this.getType()
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateDevice(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDevice(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-upload-preview {
  position: relative;
  width: 100px;
  height: 100px;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img,
  .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
  }
}

.slect_icon {
  display: flex;
  align-items: center;

  .icon_cir {
    margin-left: 30px;
    font-size: 25px;
    cursor: pointer;
  }
}
</style>

<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="vertical">
          <a-row :gutter="48">
            <a-col
              :md="5"
              :sm="24"
              v-if="!tenantId && role !== 'GS'"
            >
              <a-form-item
                label="公司"
                prop="tenantId"
              >
                <a-select
                  placeholder="请选择"
                  v-model="queryParam.tenantId"
                  style="width: 100%"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allow-clear
                >
                  <a-select-option
                    v-for="(d, index) in tenant"
                    :key="index"
                    :value="d.id"
                  >{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :md="5"
              :sm="24"
            >
              <a-form-item
                label="设备名称"
                prop="monitorCode"
              >
                <a-input
                  v-model="queryParam.devName"
                  placeholder="请输入设备名称"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col
              :md="5"
              :sm="24"
            >
              <a-form-item
                label="设备序列号"
                prop="monitorCode"
              >
                <a-input
                  v-model="queryParam.devCode"
                  placeholder="请输入设备序列号"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col
              :md="5"
              :sm="24"
              v-if="advanced"
            >
              <a-form-item
                label="是否在线"
                prop="onLine"
              >
                <a-select
                  placeholder="请选择"
                  v-model="queryParam.onLine"
                  style="width: 100%"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allow-clear
                >
                  <a-select-option
                    v-for="(d, index) in onLine"
                    :key="index"
                    :value="d.id"
                  >{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :md="5"
              :sm="24"
              v-if="advanced"
            >
              <a-form-item
                label="设备模式"
                prop="patternType"
              >
                <a-select
                  placeholder="请选择"
                  v-model="queryParam.patternType"
                  style="width: 100%"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allow-clear
                >
                  <a-select-option
                    v-for="(d, index) in patternType"
                    :key="index"
                    :value="d.id"
                  >{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :md="5"
              :sm="24"
              v-if="advanced"
            >
              <a-form-item
                label="设备类型"
                prop="devTypeId"
              >
                <a-select
                  placeholder="请选择"
                  v-model="queryParam.devTypeId"
                  style="width: 100%"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allow-clear
                >
                  <a-select-option
                    v-for="(d, index) in devType"
                    :key="index"
                    :value="d.id"
                  >{{ d.devName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :md="!advanced && 5 || 24"
              :sm="24"
            >
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}"
              >
                <a-button
                  type="primary"
                  @click="handleQuery"
                ><a-icon type="search" />查询</a-button>
                <a-button
                  style="margin-left: 8px;margin-top:28px;"
                  @click="resetQuery"
                ><a-icon type="redo" />重置</a-button>
                <a-button
                  type="primary"
                  v-if="!tenantId"
                  @click="$refs.createForm.handleAdd()"
                  style="margin-left: 8px;margin-top:28px;"
                  v-hasPermi="['gx:device:add']"
                >
                  <a-icon type="plus" />新增
                </a-button>

                <a
                  @click="toggleAdvanced"
                  style="margin-left: 8px"
                >
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!-- <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tl:devicemodel:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['tl:devicemodel:remove']"
        >
          <a-icon type="delete" />删除
        </a-button> -->
        <a-button
          type="primary"
          @click="$refs.CreateFen.handleAdd(1, selectedRowKeys)"
          v-hasPermi="['gx:device:plbm']"
          :disabled="multiple"
          v-if="role !== 'GS'"
        >
          <a-icon type="plus" />批量分配公司
        </a-button>
        <a-button
          type="primary"
          @click="$refs.CreateFen.handleAdd(2, selectedRowKeys)"
          v-hasPermi="['gx:device:pl:user']"
          :disabled="multiple"
        >
          <a-icon type="plus" />批量分配员工
        </a-button>
        <a-button
          type="primary"
          v-hasPermi="['gx:device:export']"
          @click="handleExport"
        >
          导出
        </a-button>
        <a-button
          type="primary"
          v-print="'#printContent'"
        >
          打印
        </a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <CreateFa
        ref="CreateFa"
        @ok="getList"
      ></CreateFa>
      <create-info
        ref="createInfo"
        @ok="getList"
      />
      <CreateFen
        ref="CreateFen"
        @ok="getList"
      ></CreateFen>
      <CreateDetail ref="CreateDetail"></CreateDetail>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :components="drag(columns)"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
        id="printContent"
        :scroll="{ x: 1300, y: 630 }"
      >
        <span
          slot="onLine"
          slot-scope="text, record"
        >
          <a-tag
            color="green"
            v-if="record.onLine == 1"
          >在线</a-tag>
          <a-tag
            color="red"
            v-else
          >{{ record.onLine == 2 ? '异常' : '离线' }}</a-tag>
          <!-- {{ record.onLine == 1 ? '在线' : record.onLine == 2 ? '异常' : '离线' }} -->
        </span>
        <span
          slot="patternType"
          slot-scope="text, record"
        >
          {{ record.patternType == 1 ? '手动' : '自动' }}
        </span>
        <span
          slot="earlyStatus"
          slot-scope="text, record"
        >
          {{ record.earlyStatus == 0 ? '复位' : '' }}
        </span>
        <span
          slot="solarEnergyModel"
          slot-scope="text, record"
        >
          {{ record.solarEnergyModel == 0 ? '正常 ' : '节能' }}
        </span>
        <span
          slot="sfBreathingPlate"
          slot-scope="text, record"
        >
          {{ record.sfBreathingPlate == 1 ? '否' : '是' }}
        </span>
        <span
          slot="expirationTimes"
          slot-scope="text, record"
        >
          <!-- moment(record.expirationTimes)targetDate.diff(moment(), 'days') -->
          <span :style="record.expirationStatus ? 'color:red' : ''"> {{ record.expirationTimes }}</span>
        </span>
        <div
          slot="devImgUrl"
          slot-scope="text, record"
        >
          <div v-viewer><img
            :src="record.devImgUrl"
            alt=""
            v-if="record.devImgUrl"
            class="imgs"
          ></div>
        </div>
        <span
          slot="createTime"
          slot-scope="text, record"
        >
          {{ parseTime(record.createTime) }}
        </span>
        <span
          slot="jkNUm"
          slot-scope="text, record"
        >
          <a @click="$refs.CreateDetail.handleAdd(record.id, 1)">
            {{ record.jkNUm ? record.jkNUm : 0 }}
          </a>
        </span>
        <span
          slot="operation"
          slot-scope="text, record"
        >
          <!-- <a-divider type="vertical" v-hasPermi="['tl:devicemodel:edit']" /> -->
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['gx:device:edit']"
          >
            <a-icon type="edit" />修改
          </a>
          <a-divider
            type="vertical"
            v-hasPermi="['gx:device:remove']"
          />
          <a
            @click="handleDelete(record)"
            v-hasPermi="['gx:device:remove']"
          >
            <a-icon type="delete" />删除
          </a>
          <a-divider
            type="vertical"
            v-hasPermi="['gx:devicetype:remove']"
          />
          <a-dropdown v-hasPermi="['gx:devicetype:remove']">
            <a
              class="ant-dropdown-link"
              @click="e => e.preventDefault()"
            >
              <a-icon type="double-right" />
              更多
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="$refs.CreateDetail.handleAdd(record.id, 2)">
                  来车记录
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="$refs.CreateFa.handleUpdate(record, undefined)">
                  发送数据
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="$refs.createInfo.info(record.id, undefined)">
                  详情
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <!-- <a
            @click="
                $refs.createInfo.info(record.id,
                undefined)"
                v-hasPermi="['gx:device:remove']"
              >
                详情
              </a> -->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageDevice, delDevice } from '@/api/project/device'
import { listDeviceType } from '@/api/project/deviceType'
import { listCompany } from '@/api/project/company'
import moment from 'moment'
import CreateForm from './modules/CreateForm'
import CreateFa from './modules/CreateFa.vue'
import CreateFen from './modules/CreateFen'
import CreateDetail from './modules/CreateInfo.vue'
import CreateInfo from './modules/info'
import { tableMixin } from '@/store/table-mixin'
import { mapGetters } from 'vuex'
import tableDragResize from '@/utils/tableDragResize'
export default {
  name: 'DeviceType',
  components: {
    CreateForm,
    CreateInfo,
    CreateFen,
    CreateDetail,
    CreateFa
  },
  props: ['tenantId'],
  mixins: [tableMixin, tableDragResize],
  filters: {
    yuTime (e) {
      let num
      if (e) {
        const time = moment(e).diff(moment(), 'days')
        console.log(time, 'timetimetimetime')
        if (time > 0) {
          num = time + '天后到期'
        } else {
          num = '已到期'
        }
      } else {
        num = '已到期'
      }
      return num
    }
  },
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      devType: [],
      tenant: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      patternType: [{ id: 0, name: '自动' }, { id: 1, name: '手动' }],
      onLine: [{ id: 0, name: '离线' }, { id: 1, name: '在线' }, { id: 2, name: '异常' }],
      total: 0,
      role: '',
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        name: null,
        tenantId: '',
        type: '',
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '所属部门',
          dataIndex: 'tenantName',
          width: 120,
          align: 'center',
          fixed: 'left'
        },
        {
          title: '设备名称',
          dataIndex: 'devName',
          width: 120,
          align: 'center',
          fixed: 'left'
        },
        {
          title: '设备序列号',
          dataIndex: 'devCode',
          width: 140,
          align: 'center',
          fixed: 'left'
        },
        {
          title: '类型名称',
          dataIndex: 'devTypeName',
          width: 80,
          align: 'center'
        },
        {
          title: '设备图片',
          dataIndex: 'devImgUrl',
          width: 80,
          scopedSlots: { customRender: 'devImgUrl' },
          align: 'center'
        },
        {
          title: '视频数',
          dataIndex: 'jkNUm',
          width: 80,
          scopedSlots: { customRender: 'jkNUm' },
          align: 'center'
        },
        {
          title: '设备状态',
          dataIndex: 'onLine',
          width: 80,
          scopedSlots: { customRender: 'onLine' },
          align: 'center'
        },
        {
          title: '太阳能模式',
          dataIndex: 'solarEnergyModel',
          width: 100,
          scopedSlots: { customRender: 'solarEnergyModel' },
          align: 'center'
        },
        // {
        //   title: '供电',
        //   dataIndex: 'powerSupply',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '是否息屏',
        //   dataIndex: 'sfBreathingPlate',
        //   scopedSlots: { customRender: 'sfBreathingPlate' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '预警状态',
          dataIndex: 'earlyStatus',
          width: 80,
          scopedSlots: { customRender: 'earlyStatus' },
          align: 'center'
        },
        {
          title: '设备模式',
          dataIndex: 'patternType',
          width: 80,
          scopedSlots: { customRender: 'patternType' },
          align: 'center'
        },
        {
          title: '流量期限',
          dataIndex: 'expirationTimes',
          width: 100,
          scopedSlots: { customRender: 'expirationTimes' },
          align: 'center'
        },
        {
          title: '上线时间',
          dataIndex: 'onlineTime',
          width: 120,
          align: 'center'
        },
        {
          title: '离线时间',
          dataIndex: 'offTime',
          width: 120,
          align: 'center'
        },
        {
          title: '设备版本',
          dataIndex: 'devVersion',
          width: 80,
          align: 'center'
        },
        {
          title: '设备地址',
          dataIndex: 'addressName',
          align: 'center',
          width: 120
        },
        // {
        //   title: '添加时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          fixed: 'right'
        }
      ]
    }
  },
  created () {
    console.log(this.$route)
    if (this.$route.path == '/car') {
      this.queryParam.type = 1
    } else {
      this.queryParam.type = 0
    }
    const devCode = this.$route.params.devCode
    if (devCode) {
      this.queryParam.devCode = devCode
    }
    console.log(this.$route.params.devCode, '对对对的多多多')
    this.queryParam.tenantId = this.tenantId
    const num = this.columns.length - 1
    this.role = this.roles[0]
    if (this.tenantId) {
      this.columns.splice(num, 1)
    } else {
      if (this.role === 'GS' || this.role === 'YG') {
        this.queryParam.tenantId = this.tenantIds
      } else {
        listCompany().then(res => {
          this.tenant = res.data
        })
      }
    }
    if (this.role === 'GS') {
      this.queryParam.tenantId = this.tenantIds
    }
    this.getList()
    listDeviceType().then(res => {
      this.devType = res.data
    })

    console.log(this.tenantIds, 'tenantIdstenantIdstenantIds')
  },
  computed: {
    ...mapGetters([
      'roles',
      'tenantIds'
    ])
  },
  watch: {
    tenantId: {
      handler (val, oldVal) {
        // console.log(val, 'valvalval')
        this.queryParam.tenantId = val
        if (val) {
          this.getList()
        }
      },
      immediate: true
    }
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('gx/gx-device/export', {
            ...that.queryParam
          }, `设备信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () { }
      })
    },
    /** 查询型号信息列表 */
    getList () {
      this.loading = true
      pageDevice(this.queryParam).then(response => {
        response.data.records.forEach(v => {
          if (v.expirationTime) {
            const time = moment(v.expirationTime).diff(moment(), 'days')
            if (time > 0) {
              v.expirationTimes = time + '天后到期'
              v.expirationStatus = 0
            } else {
              v.expirationTimes = '已到期'
              v.expirationStatus = 1
            }
          } else {
            v.expirationTimes = '已到期'
            v.expirationStatus = 1
          }
        })
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },

    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        name: undefined,
        pageNum: 1,
        tenantId: undefined,
        pageSize: 10
      }
      this.queryParam.tenantId = this.tenantId
      if (this.role === 'GS') {
        this.queryParam.tenantId = this.tenantIds
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        // content: '确认删除所选中数据?',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delDevice(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () { }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.imgs {
  width: 50px;
  height: 50px;
}

/deep/.resize-table-th {
  position: relative;

  .table-draggable-handle {
    height: 100% !important;
    bottom: 0;
    left: auto !important;
    right: -5px;
    cursor: col-resize;
    touch-action: none;
    position: absolute;
  }
}
</style>
